import http from "@/utils/request";
// 获取列表
export function getofficeList(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getOffice.nd",
        data
    });
}

  
export function getSupplierNameByOrg(data) {
    return http({
        method: "get",
        url: "/custAgentActivity/getSupplierNameByOrg.nd",
        params:data
    });
}

export function getDLlnumSeach(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getAgent.nd",
        data
    });
}

export function getFXlnumSeach(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getDistributor.nd",
        data
    });
}
export function getDLMDlnumSeach(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getCyShop.nd",
        data
    });
}
export function getUsers(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getUsers.nd",
        data
    });
}

export function getztUsers(data) {
    return http({
        method: "post",
        url: "/distributeBoothReport/findOfficeManager.nd",
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

export function activityApplication(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/flowStart.nd",
        data,
        // contentType: "application/x-www-form-urlencoded",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

// 查看单条数据进行编辑 /custAgentActivity/getActivityById.nd
export function getActivityInfo(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getActivityById.nd",
        data
    });
}
// 获取活动编码字段
export function getActivityCode(data) {
    return http({
        method: "get",
        url: "/custAgentActivity/getActivityCode.nd",
        data
    });
}

// 查询参与品类的下拉项
export function getMatklList(data) {
    return http({
        method: "get",
        url: "/custAgentActivity/getMatklByCust.nd",
        params:data
    });
}

//获取活动属性
export function getActivityAttribute() {
    return http({
        method: "get",
        url: "/custAgentActivity/getActivityAttribute.nd",
    });
}
