import cancelreasonModal from "./../../agentVerificationActivities/components/cancelReason.vue";
import {
  queryOrg,
  getShopList,
  isSpecialShop,
  getMaterialGroups,
  getXQtype,
  getFinOrderType,
  SupplierComboBox,
  findShopDetail,
  tmpSaveInfo, flowStart, findDetail,
  findCustomerShopAnnualSalesScale, findGroundMaterialList,
  findHallTypeList,
  findTopMaterialList
} from "./api";
import axios from "axios";
import { publicPath } from "@/common/constant.js";
export default {
  components: {
    cancelreasonModal,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/boothStoreExpenses",
          name: "boothStoreExpenses",
          title: "展台需求提报"
        },
        {
          path: "boothStoreExpenses/boothStoreAddAndEdit",
          name: "boothStoreEdit",
          title:  this.$route.query.type=='add'?'新增':"编辑"
        }
      ],
      fetching: false,
      pageLoadFlag: false,
      detilInfo: {},
      columns: [
        {
          title: "需求子单",
          dataIndex: "type",
          width: 300,
          scopedSlots: { customRender: "type" }
        },

        {
          title: "门店*",
          dataIndex: "shopStore",
          width: 360,
          scopedSlots: { customRender: "shopStore" }
        },
        {
          title: "是否专卖店*",
          dataIndex: "specialtyStore",
          width: 120,
          scopedSlots: { customRender: "specialtyStore" }
        },
        {
          title: "物料组*",
          dataIndex: "materialGroup",
          width: 230,
          scopedSlots: { customRender: "materialGroup" }
        },
        {
          title: "分销网络规模(万元)",
          dataIndex: "FXnet",
          width: 180,
          scopedSlots: { customRender: "FXnet" }
        },
        {
          title: "所属办事处*",
          dataIndex: "Office",
          width: 360,
          scopedSlots: { customRender: "Office" }
        },
        {
          title: "门店级别",
          dataIndex: "shopLevelName",
          width: 230,
          scopedSlots: { customRender: "shopLevel" }
        },
        {
          title: "门店展厅面积(㎡)*",
          dataIndex: "shopArea",
          width: 230,
          scopedSlots: { customRender: "shopArea" }
        },
        {
          title: "展厅延米*",
          dataIndex: "shopLinearMeter",
          width: 230,
          scopedSlots: { customRender: "shopLinearMeter" }
        },
        // {
        //   title: "厅型*",
        //   dataIndex: "shaped",
        //   width: 230,
        //   scopedSlots: { customRender: "shaped" }
        // },
        // {
        //   title: "顶材质*",
        //   dataIndex: "topMaterial",
        //   width: 230,
        //   scopedSlots: { customRender: "topMaterial" }
        // },
        // {
        //   title: "地材质*",
        //   dataIndex: "groundMaterial",
        //   width: 230,
        //   scopedSlots: { customRender: "groundMaterial" }
        // },
        {
          title: "需求类型*",
          dataIndex: "demandType",
          width: 200,
          scopedSlots: { customRender: "demandType" }
        },
        {
          title: "是否品牌园*",
          dataIndex: "isBuildBrandpark",
          width: 300,
          scopedSlots: { customRender: "isBrand" }
        },
        {
          title: "派单类型*",
          dataIndex: "DispatchOrderType",
          width: 200,
          scopedSlots: { customRender: "DispatchOrderType" }
        },
        {
          title: "供应商",
          dataIndex: "supplier",
          width: 360,
          scopedSlots: { customRender: "supplier" }
        },
        {
          title: "费用预估(元)*",
          dataIndex: "costEstimate",
          width: 220,
          scopedSlots: { customRender: "costEstimate" }
        },
        {
          title: "要求完成时间*",
          dataIndex: "FinishedTime",
          width: 200,
          scopedSlots: { customRender: "FinishedTime" }
        },
        {
          title: "进场起一年内目标销量(台)",
          dataIndex: "sales",
          width: 230,
          scopedSlots: { customRender: "sales" }
        },
        {
          title: "进场起一年内目标销售额(万元)",
          dataIndex: "salesVolume",
          width: 230,
          scopedSlots: { customRender: "salesVolume" }
        },

        {
          title: "说明*",
          dataIndex: "remark",
          width: 400,
          scopedSlots: { customRender: "remark" }
        },
        {
          title: "展台使用年限(年)*",
          dataIndex: "firstYear",
          width: 550,
          scopedSlots: { customRender: "firstYear" }
        }

      ],
      selectedRowKeys: [],

      tableData: [], // 物料组数据


      fileList: [
        // {
        //   name:11
        // }
      ],
      fileIds: [],
      delFileId: [], // 删除的图片id
      previewVisible: false,
      previewImage: "",
      marketCenterList: [], // 营销中心数据
      marketCenterId: undefined, //营销中心id
      marketCenter: "",
      officeList: [
        {
          code: 0,
          name: "展台制作费-非专卖店"
        }
      ],// 计划项目名称
      officeName: 0,// 办事处
      officeId: 0, // 办事处id
      timeStart: "", // 活动时间


      FXDLlnum: "", // 模糊搜索 门店id
      shopList: [], // 模糊搜索出来的门店数据

      processInstId: "",
      therm: "",// 需求项目名称
      supplyCode: "",// 主单号
      remark: "",//说明
      visible: false,
      tableDataOne: [],

      deletArr: [],

      materialGroup: [], // 物料组
      demandList: [], //需求类型
      dispatchOrderTypeList: [],//派单类型
      supplier: [], // 供应商id
      SupplierList: [], // 供应商列表
      searchStr: "",// 供应商模糊搜索输入字段
      visible2: false,
      selctRow: {}, //当前选择的row
      msgTips: "",
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      BrandList:[
        {
          code:'0',
          name:'否'
        },
        {
          code:'1',
          name:'是'
        }
      ],
      topMaterialList:[],//顶材质
      groundMaterialList:[],//底材质
      isLoginModalShow:false,
      addmessage:false,
      delIndex:'',
      maoyirules: {
        isHeaderUnion: [
          { required: true ,message: "请选择派单类型", trigger: ["change", "blur"],}
        ],
        shopId: [
          { required: true, message: "请选择门店", trigger: "blur" }
        ],
        baseMatklId: [
          { required: true, message: "请选择物料组", trigger: "blur" },
        ],
        shopArea: [
          { required: true, message: "请填写门店展台面积", trigger: "blur" },
        ],
        shopLinearMeter: [
          { required: true, message: "请填写展台延米", trigger: "blur" },
        ],
        supplyTypeId: [
          { required: true, message: "请选择需求类型", trigger: "blur" },
        ],
        isBuildBrandpark: [
          { required: true, message: "请选择是否品牌园", trigger: "blur" },
        ],
        costEstimate: [
          { required: true, message: "请填写费用预估", trigger: "blur" },
        ],
        completionTime: [
          { required: true, message: "请选择要求完成时间",   trigger: ["change", "blur"] },
        ],
        curStartYear: [
          { required: true, message: "请选择展台使用年限", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请填写说明", trigger: "blur" },
        ],
      },
      FXnet:[],
      shapedList:[],
      addFrom:{
        isHeaderUnion:'0',
        isHeaderUnionText:'普通安装派单',
        shopId:undefined,
        shopFullName:'',
        isSpecialShopText:'',
        isSpecialShop:'',
        fetching:false,
        baseMatklId:undefined,
        baseMatklName:'',
        officeId:'',
        shopLevelName:'',
        shopArea:'',
        disabled:'',
        SupplierId:undefined,
        SupplierName:'',
        fetching2:false,
        isBuildBrandpark:undefined,
        cisDetailId:'',
        costEstimate:'',
        FXnet:'',
        completionTime:'',
        office:'',
        saleT:'',
        supplyTypeId:'14182400546',
        supplyTypeName:'新店开业',
        salesVolumes:'',
        remark:'',
        curStartYear:'',
        curEndYear:'',
        type:'',
      },
      showAddFlag:false,
      editType:'',
      editIndex:''
    };
  },


  mounted() {

    this.getYYXT();
    this.type = this.$route.query.type;
    this.getDemandList();
    this.getPDtype();
    if(this.$route.query.type=='edit'){
      this.getSignleInfo(this.$route.query.id);
    }
    this.getshapedList()
    this.getMaterialList()

  },

  methods: {
    // 编辑
    editRow(type,item,index){
      // 根据门店id 查询物料组 materialGroup物料组数组
      getMaterialGroups({
        customerShopId: this.addFrom.shopId,
        orgId: this.marketCenterId
      }).then(res => {
        this.materialGroup = res.data.data[0].params;
        this.$forceUpdate();
      });
      this.editIndex= index
      this.editType = type
      this.addFrom = JSON.parse(JSON.stringify(item))
      this.addmessage = true
    },
    delectRow(index){
      this.delIndex = index
      this.isLoginModalShow =true
    },
    delect(){
      this.tableData.splice( this.delIndex , 1)
    },
    deletemessage(){
      this. addFrom = {
        isHeaderUnion:'0',
        isHeaderUnionText:'普通安装派单',
        shopId:'',
        shopFullName:'',
        isSpecialShopText:'',
        isSpecialShop:'',
        fetching:false,
        baseMatklId:'',
        baseMatklName:'',
        officeId:'',
        shopLevelName:'',
        shopArea:'',
        disabled:'',
        SupplierId:'',
        SupplierName:'',
        fetching2:false,
        isBuildBrandpark:'',
        cisDetailId:'',
        costEstimate:'',
        FXnet:'',
        completionTime:'',
        office:'',
        saleT:'',
        supplyTypeId:'14182400546',
        supplyTypeName:'新店开业',
        salesVolumes:'',
        remark:'',
        curStartYear:'',
        curEndYear:'',
        type:'',
      }
      this.addmessage = false

    },
    mousemove(item){
      item.showFlag = true
      this.$forceUpdate()
    },
    mouseleave(item){
      item.showFlag = false
      this.$forceUpdate()
    },
//表单验证
    maoyionSubmit() {
      this.$refs.maoyiruleForm.validate((valid) => {
        if (valid) {
          if(this.editType=='add'){
            this.tableData.push(JSON.parse(JSON.stringify(this.addFrom)))
            this.tableData =  this.tableData.map(el => {
              return {
                ...el,
                showFlag: false
              }
            })
            this. addFrom = {
                isHeaderUnion:'0',
                isHeaderUnionText:'普通安装派单',
                shopId:'',
                shopFullName:'',
                isSpecialShopText:'',
                isSpecialShop:'',
                fetching:false,
                baseMatklId:'',
                baseMatklName:'',
                officeId:'',
                shopLevelName:'',
                shopArea:'',
                disabled:'',
                SupplierId:'',
                SupplierName:'',
                fetching2:false,
                isBuildBrandpark:'',
                cisDetailId:'',
                costEstimate:'',
                FXnet:'',
                completionTime:'',
                office:'',
                saleT:'',
                supplyTypeId:'14182400546',
                supplyTypeName:'新店开业',
                salesVolumes:'',
                remark:'',
                curStartYear:'',
                curEndYear:'',
                type:'',
            }
            this.addmessage = false
          }
          if(this.editType =='edit'){
            this.tableData[this.editIndex] = JSON.parse(JSON.stringify(this.addFrom))
            this. addFrom = {
              isHeaderUnion:'0',
              isHeaderUnionText:'普通安装派单',
              shopId:'',
              shopFullName:'',
              isSpecialShopText:'',
              isSpecialShop:'',
              fetching:false,
              baseMatklId:'',
              baseMatklName:'',
              officeId:'',
              shopLevelName:'',
              shopArea:'',
              disabled:'',
              SupplierId:'',
              SupplierName:'',
              fetching2:false,
              isBuildBrandpark:'',
              cisDetailId:'',
              costEstimate:'',
              FXnet:'',
              completionTime:'',
              office:'',
              saleT:'',
              supplyTypeId:'14182400546',
              supplyTypeName:'新店开业',
              salesVolumes:'',
              remark:'',
              curStartYear:'',
              curEndYear:'',
              type:'',
            }
            this.addmessage = false
          }

        } else {
          this.$message.warning("请将信息填写完整");
          return false;
        }
      });
    },

    // 获取ding材质
    getshapedList () {
      findTopMaterialList({}).then(res=>{
        if(res.data.code == 0){
          this.topMaterialList = res.data.data
        }
      })
    },
    // 获取di材质
    getMaterialList () {
      findGroundMaterialList({}).then(res=>{
        if(res.data.code == 0){
          this.groundMaterialList = res.data.data
        }
      })
    },
    //修改厅材质
    handleChangeshaped(e, record) {
      record.shapedId = e;
      let obj = record.shapedList.find((i) => {//listData就是上面的数据源
        return i.code === e;//筛选出匹配数据
      });
      record.shapedName = obj.name;
    },
    // 修改顶材质
    handleChangetopMaterial(e, record) {
      record.topMaterialId = e;
      let obj = this.topMaterialList.find((i) => {//listData就是上面的数据源
        return i.code === e;//筛选出匹配数据
      });
      record.topMaterialName = obj.name;
    },
    // 修改地材质
    handleChangegroundMaterial(e, record) {
      record.groundMaterialId = e;
      let obj = this.groundMaterialList.find((i) => {//listData就是上面的数据源
        return i.code === e;//筛选出匹配数据
      });
      record.groundMaterialName = obj.name;
    },

    // 是否品牌
    handleChangeBrand(value,) {
      this.addFrom.isBuildBrandpark = value;
      this.$forceUpdate()
    },
    handleChangeStart(value) {
      this.addFrom.curStartYear = value;
      if (this.addFrom.curEndYear && (this.addFrom.curStartYear > this.addFrom.curEndYear)) {
        this.$message.warning("输入时间不能大于结束时间,请重新选择");
        this.addFrom.curStartYear = "";
        this.$forceUpdate()
        return;
      }

    },
    handleChangeEnd(value) {
      this.addFrom.curEndYear = value;
      if (this.addFrom.curEndYear < this.addFrom.curStartYear) {
        this.$message.warning("输入时间小于开始时间,请重新选择");
        this.addFrom.curEndYear = "";
        this.$forceUpdate()
        return;
      }
      this.$forceUpdate()
    },
    // 查询详情
    getSignleInfo(id) {
      this.pageLoadFlag = true;
      let data = {
        id: id
      };
      findDetail(data).then(res => {
        if (res.data.code == 0) {
          this.marketCenterId = String(res.data.data.orgId);
          this.therm = res.data.data.projectSupplyName // 需求项目名称
          this.officeName = res.data.data.projectPlanText  // 计划项目名称
          this.supplyCode = res.data.data.supplyCode  // 主单号
          this.remark = res.data.data.remark
          if (res.data.data.attachs && res.data.data.attachs.length > 0) {
            res.data.data.attachs.forEach(item => {
              this.fileIds.push(item.id)
              this.fileList.push(
                {
                  uid: item.id,
                  name: item.attachName,
                  status: "done",
                  url: item.attachPath,
                  name2: "旧的"
                }
              );
            });
          }
          this.tableData = res.data.data.supplyDetailList;
          this.tableDataOne = JSON.parse(JSON.stringify(res.data.data.supplyDetailList));
          if (this.tableData.length > 0) {
            this.tableData.forEach(item => {
              item.showFlag = false
              item.fetching2 = false;
              item.fetching = false;
              item.type = item.supplyDetailCode;
              if (item.custShopInfoDto && item.custShopInfoDto.fullName) {
                this.getShopList(item.custShopInfoDto.fullName, item);

              }
              if (item.custShopInfoDto && item.custShopInfoDto.id) {
                item.shopId = String(item.custShopInfoDto.id);
                item.shopName = item.custShopInfoDto.fullName;
                item.shopFullName = item.custShopInfoDto.fullName;
              }
              if (item.custShopInfoDto && item.custShopInfoDto.isSpecialShopText) {
                item.isSpecialShopText = item.custShopInfoDto.isSpecialShopText;
                item.isSpecialShop = item.custShopInfoDto.isSpecialShop;
              }
              // 根据门店id 查询物料组 materialGroup物料组数组
              let isData = {
                customerShopId: item.shopId,
                orgId: this.marketCenterId
              };
              getMaterialGroups(isData).then(resA => {
                item.materialGroup = resA.data.data[0].params;
                this.$forceUpdate();
              });
              item.shapedId = item.shapeId?item.shapeId.toString():''
              item.topMaterialId = item.topMaterialId?item.topMaterialId.toString():''
              item.groundMaterialId = item.topMaterialId?item.groundMaterialId.toString():''
              if (item.custShopInfoDetailDto) {
                item.baseMatklId = String(item.custShopInfoDetailDto.baseMatklId);
                item.baseMatklName = item.custShopInfoDetailDto.baseMatklName;
                item.cisDetailId = item.custShopInfoDetailDto.cisDetailId;
                let dataTwo = {
                  matklName:item.baseMatklName
                }
                findHallTypeList(dataTwo).then(res=>{
                  if(res.data.code == 0){
                    item.shapedList = res.data.data
                    this.$forceUpdate()
                  }
                })
              }
              item.office = item.custShopInfoDetailDto ? item.custShopInfoDetailDto.orgName : "";
              item.officeId = item.custShopInfoDetailDto ? item.custShopInfoDetailDto.orgId : "";
              item.shopLevelName = item.custShopInfoDetailDto ? item.custShopInfoDetailDto.shopLevelName:''
              if (item.supplyType && item.supplyType.id) {
                item.supplyTypeId = String(item.supplyType.id);
              }
              item.isHeaderUnion = item.isHeaderUnion ? String(item.isHeaderUnion) : "";
              if (item.isHeaderUnion == 2) {
                item.disabled = false;
              } else {
                item.disabled = true;
              }
              if (item.supplierName) {
                this.getSupplier(item.supplierName, item);
              }
              item.SupplierId = item.supplierCode;
              item.SupplierName = item.supplierName;
              item.costEstimate = item.estimatedCost;
              item.completionTime = item.finishDate;
              item.saleT = item.targetNumber;
              item.isBuildBrandpark = item.isBuildBrandPark?String(item.isBuildBrandPark):''
              item.salesVolumes = item.targetMoney;
              // 分销网络规模
              item.FXnet = item.annualSalesScale;
              // 展台使用年限*
              if (item.boothServiceLife) {
                this.$set(item,'curStartYear',item.boothServiceLife.split("-")[0])
                this.$set(item,'curEndYear',item.boothServiceLife.split("-")[1])
                // item.curStartYear = String(item.boothServiceLife.split("-")[0]);
                // item.curEndYear = String(item.boothServiceLife.split("-")[1]);

              }
              item.supplyTypeName ='新店开业'
              item.supplyTypeId='14182400546'
            });
          }
          this.pageLoadFlag = false;
        } else {
          this.$message.warning('获取详情失败，'+res.data.msg)
          this.pageLoadFlag = false;
        }
      });

    },
    //获取立项组织数据
    getYYXT() {
      let data = {};
      queryOrg(data).then(res => {
        this.marketCenterList = res.data.orgList;
      });
    },
    // 更改立项组织
    handleChangeshopList(value, e) {
      this.marketCenterId = value;
      this.marketCenter = e.key;
      this.tableData = [];
    },
    // 更改需求项目名称
    changeThem(e) {
      if (e.target.value.length >= 100) {
        this.$message.warning("最多输入100个文字");
        return false;
      }
    },
    // 更改项目计划名称 （暂时用不到）
    handleChangeofficeList(value, e) {
      this.officeName = value;
      this.officeId = e.key;
    },
    // 更改说明
    changeplace(e) {
      if (e.target.value.length >= 100) {
        this.$message.warning("最多输入100个文字");
        return false;
      }
    },
    // 附件上传
    beforeUpload(file) {
      this.pageLoadFlag = true;
      const _this = this; // 文件大小不能超过十兆
      const size = file.size / 1024 / 1024 < 20;
      return new Promise((resolve, reject) => {
        if (!size) {
          this.$message.warning("文件大小不能超过20M！");
          this.pageLoadFlag = false;
          reject(file);
        } else {
          if (size) {
            const formData = new FormData();
            formData.append("file1", file);
            formData.append("fileType", "boothDemandReport");
            // 上传接口/custAgentActivity/uploadFile.nd
            let config = {
              headers: { "content-type": "multipart/form-data" }
            };
            // 发送请求
            const BASE_API = publicPath;
            axios.post(BASE_API + "/comm/method=uploadFile.nd", formData, config).then(res => {
              if (res.data.message == "上传成功") {
                this.fileIds = [];
                this.fileIds.push({
                  id: res.data.businessId
                });
                this.fileList = [..._this.fileList, file];
                _this.fileList = _this.fileList.slice(-1);
                this.pageLoadFlag = false;
              } else {
                this.$message.warning("上传失败," + res.data.message);
                this.pageLoadFlag = false;
              }
            });
            return false;
          }
        }

      });
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1);
      if (this.type == "edit") {
        // 增加一个标识  只有是之前的数据删除掉才push
        if (file.name2 && file.name2 == "旧的") {
          this.delFileId.push({
            id: file.uid
          });
        }

      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (file.url) {
        window.open(file.url);
      } else {
        // if (!file.url && !file.preview) {
        //   file.preview = await getBase64(file.originFileObj);
        // }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      }
    },
    // 下载
    handleDownload(file) {
      window.open(file.url, "_blank");
    },
    // 上传附件代码结束
    // 需求子单
    search() {},
    // 模糊搜索门店
    getShopList(value) {
      this.FXDLlnum = value;
      let dataFx = {
        searchKeyWords: this.FXDLlnum || '',
        isSpecialShop: "212400",
        orgId: this.marketCenterId,
      };
      this.addFrom.fetching = true;
      getShopList(dataFx).then(res => {

        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
          this.addFrom.fetching = false;
        } else {
          data = res.data;
        }
        this.addFrom.fetching = false;
        this.$forceUpdate();
        this.shopList = [...new Set(data.data)]; //缓存列表数据，前端分页
        this.fetching = false;
      });
    },
    //更改门店
    changeShop(value) {
      this.FXDLlnum = value;
      // 门店id
      this.addFrom.shopId = value;
      //  调用是否是专卖店接口
      let obj = this.shopList.find((i) => {//listData就是上面的数据源
        return i.code === value;//筛选出匹配数据
      });
      // 门店名称
      this.addFrom.shopFullName = obj.name;
      // 是否是专卖店（需要调用接口 传值需要id目前写死）
      // row.isSpecialShopText = '否';
      // row.isSpecialShop = "0";
      let isData = {
        customerShopId: value
      };
      isSpecialShop(isData).then(async (res) => {
        this.addFrom.isSpecialShopText = res.data.data.isSpecialShopText;
        this.addFrom.isSpecialShop = res.data.data.isSpecialShop;
        this.$forceUpdate();
      });

      let isDataShop = {
        customerShopId: value,
        orgId: this.marketCenterId
      };
      // 根据门店id 查询物料组 materialGroup物料组数组
      getMaterialGroups(isDataShop).then(res => {
        this.materialGroup = res.data.data[0].params;
        this.$forceUpdate();
      });
      this.addFrom.baseMatklId = "";
      this.addFrom.baseMatklName = "";
      this.addFrom.office = "";
      this.addFrom.officeId = "";
      this.addFrom.shopLevelName = ''
      this.addFrom.shopArea = ''
    },
    // 获取需求类型列表
    getDemandList() {
      getXQtype({}).then(res => {
        if (res.data.code == 0) {
          this.demandList = res.data.list;
        }
      });
    },
    // 更改需求类型
    handleChangeSupplyType(e) {
      this.addFrom.supplyTypeId = e;
      let obj = this.demandList.find((i) => {//listData就是上面的数据源
        return i.code === e;//筛选出匹配数据
      });
      this.addFrom.supplyTypeName = obj.name;
      //   判断派单类型为xx 弹出弹窗 弹窗内容根据选择的类型变化
    },
    // 派单类型列表
    getPDtype() {
      getFinOrderType({}).then(res => {
        if (res.data.code == 0) {
          this.dispatchOrderTypeList = res.data.data;
          this.addFrom.isHeaderUnion = this.dispatchOrderTypeList[0].code
          this.addFrom.isHeaderUnionText = this.dispatchOrderTypeList[0].name
        }
      });
    },
    // 更改拍单类型
    handleChangeDispatchOrderType(e) {
      // isHeaderUnion: 0
      // isHeaderUnionText: "普通安装派单"
      this.addFrom.isHeaderUnion = e;
      let obj = this.dispatchOrderTypeList.find((i) => {//listData就是上面的数据源
        return i.code === e;//筛选出匹配数据
      });
      this.addFrom.isHeaderUnion = obj.code;
      this.addFrom.isHeaderUnionText = obj.name;
      if (this.addFrom.isHeaderUnion == "1") {
        this.msgTips = "仅限于总部重点门店建店使用，不会自动派单，将由总部建店负责人在NP-SRM直接派工至总部指定搭建方，请再次确认！";
        this.visible2 = true;
        this.addFrom.SupplierId = "";
        this.addFrom.SupplierName = "";
        this.$forceUpdate();
      } else if (this.addFrom.isHeaderUnion == "2") {
        this.msgTips = "仅针对连锁渠道指定商家（如：顶、地制作），请再次确认是否要进行连锁统装派单？如选择此项，后期将纳入审计重点核查门店！";
        this.visible2 = true;
        this.$forceUpdate();
      } else {
        this.addFrom.SupplierId = "";
        this.addFrom.SupplierName = "";
        this.addFrom.disabled = true;
        this.visible2 = false;
        this.$forceUpdate();
      }
    },
    // 点击确定弹窗删除
    handleOk() {
      if (this.addFrom.isHeaderUnion == 2) {
        this.addFrom.disabled = false;
        this.$forceUpdate();
      } else {
        this.addFrom.SupplierId = "";
        this.addFrom.SupplierName = "";
        this.addFrom.disabled = true;
        this.$forceUpdate();
      }
      this.visible2 = false;
    },
    // 点击取消 把当前的 需求类型置空
    handleCancelMessage() {
      this.visible2 = false;
      this.addFrom.disabled = true;
      this.addFrom.isHeaderUnion = "0";
      this.addFrom.isHeaderUnionText = "普通安装派单";
      this.addFrom.SupplierId = "";
      this.addFrom.SupplierName = "";
      this.$forceUpdate();
    },
    // 模糊搜索供应商
    searchSupplier() {},
    // 模糊搜索供应商
    getSupplier(value) {
      this.searchStr = value;
      let dataRequest = {
        searchKeyWord: this.searchStr
      };
      this.addFrom.fetching2 = true;
      SupplierComboBox(dataRequest).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
          this.addFrom.fetching2 = false;
        } else {
          data = res.data;
        }
        this.addFrom.fetching2 = false;
        this.$forceUpdate();
        this.SupplierList = [...new Set(data.data)]; //缓存列表数据，前端分页
      });
    },
    //更改供应商
    async changeSupplier(value) {
      this.searchStr = value;
      // 门店id
      this.addFrom.SupplierId = value;
      //  调用是否是专卖店接口
      let obj = this.SupplierList.find((i) => {//listData就是上面的数据源
        return i.code === value;//筛选出匹配数据
      });
      // 供应商名称
      this.addFrom.SupplierName = obj.name;

    },
    // 更改物料组
    handleChangeshopList3(e) {
      this.addFrom.officeId = "";
      this.addFrom.office = "";
      this.addFrom.shopLevelName = "";
      this.addFrom.cisDetailId = "";
      this.addFrom.baseMatklId = e;
      let obj = this.materialGroup.find((i) => {//listData就是上面的数据源
        return i.code === e;//筛选出匹配数据
      });
      this.addFrom.baseMatklName = obj.name;
      let data = {
        customerShopId: this.addFrom.shopId,
        materialGroupId: this.addFrom.baseMatklId
      };
      // 调用 分销商网络规模
      findCustomerShopAnnualSalesScale(data).then(res => {
        this.addFrom.FXnet = res.data.data.annualSalesScale?res.data.data.annualSalesScale:'';
        this.$forceUpdate();
      });
      //需要调用办事处接口
      findShopDetail(data).then(async (res) => {
        this.addFrom.office = res.data.data.orgName;
        this.addFrom.officeId = res.data.data.orgId;
        this.addFrom.cisDetailId = res.data.data.cisDetailId;
        this.addFrom.shopLevelName = res.data.data.shopLevelName
        this.addFrom.shopArea = res.data.data.shopArea
        this.$forceUpdate();

      });
      let dataTwo = {
        matklName:this.addFrom.baseMatklName
      }
      findHallTypeList(dataTwo).then(resTypeList=>{
        if(resTypeList.data.code == 0){
          this.shapedList = resTypeList.data.data
        }
      })
    },

    // 更改
    handleChangeshopList2() {
      //  更改选项的时候判断当前数据和接口数据的值是否一致 赋值新的id
      this.MarketTableList.forEach(marItem => {
        this.tableData1.forEach(item => {
          if (marItem.matklName == item.matklName) {
            item.matklId = marItem.id;
          }
        });
      });
    },
    // 暂存
    temporaryStorage() {
      this.getDeletInfo();
      if (!this.marketCenterId) {
        this.$message.warning("请先填写立项组织!");
        return;
      }
      if (!this.therm) {
        this.$message.warning("请先填写需求项目名称!");
        return;
      }
      // if (!this.remark) {
      //   this.$message.warning("请先填写说明!");
      //   return;
      // }
      let detailVOList = [];
      if (this.tableData.length > 0) {
        this.tableData.forEach(item => {

          detailVOList.push({
            id: item.id,
            exhibitionStandReportId: "",
            materialGroupId: item.baseMatklId ? item.baseMatklId : "",
            customerShopId: item.shopId ? item.shopId : "",
            customerShopDetailId: item.cisDetailId ? item.cisDetailId : "", //item.officeId?item.officeId:
            demandTypeId: item.supplyTypeId ? item.supplyTypeId : "",
            sendOrderTypeCode: item.isHeaderUnion ? item.isHeaderUnion : "",
            supplierCode: item.SupplierId ? item.SupplierId : "",
            estimatedCost: item.costEstimate ? item.costEstimate : "",
            scheduledFinishTime: item.completionTime ? item.completionTime : "",
            targetSalesValWithinOneYear: item.saleT ? item.saleT : "",
            targetSalesMoneyWithinOneYear: item.salesVolumes ? item.salesVolumes : "",
            remark: item.remark ? item.remark : "",
            officeId: item.officeId ? item.officeId : "",
            boothServiceLife: item.curStartYear && item.curEndYear ? item.curStartYear + "-" + item.curEndYear : "",
            annualSalesScale: item.FXnet,
            isBuildBrandPark:item.isBuildBrandpark,
            shopArea:item.shopArea,
            shopLinearMeter:item.shopLinearMeter,
            shapeId:item.shapedId,
            shapeText:item.shapedName,
            topMaterialId:item.topMaterialId,
            topMaterialText:item.topMaterialName,
            groundMaterialId:item.groundMaterialId,
            groundMaterialText:item.groundMaterialName
          });
        });

        for (let i = 0; i < detailVOList.length; i++) {
          if (detailVOList[i]) {
            if (detailVOList[i].isBuildBrandPark == "") {
              this.$message.warning(`第${i + 1}行是否品牌园不能为空,请选择`);
              return;
            }
            if (detailVOList[i].customerShopId == "") {
              this.$message.warning(`第${i + 1}行门店不能为空,请选择`);
              return;
            }
            if (detailVOList[i].materialGroupId == "") {
              this.$message.warning(`第${i + 1}行物料组不能为空,请选择`);
              return;
            }
            if (detailVOList[i].demandTypeId == "") {
              this.$message.warning(`第${i + 1}行需求类型不能为空,请选择`);
              return;
            }
            if (detailVOList[i].sendOrderTypeCode == "") {
              this.$message.warning(`第${i + 1}行派单类型不能为空,请选择`);
              return;
            }
            if (detailVOList[i].estimatedCost == "") {
              this.$message.warning(`第${i + 1}行费用预估不能为空,请填写`);
              return;
            }
            if (detailVOList[i].estimatedCost == "") {
              this.$message.warning(`第${i + 1}行费用预估不能为空,请填写`);
              return;
            }
            if (detailVOList[i].scheduledFinishTime == "") {
              this.$message.warning(`第${i + 1}行要求完成时间不能为空,请选择`);
              return;
            }
            if (detailVOList[i].remark == "") {
              this.$message.warning(`第${i + 1}行说明不能为空,请填写`);
              return;
            }
            if (detailVOList[i].boothServiceLife == "") {
              this.$message.warning(`第${i + 1}行展台使用年限不能为空或者填写不完整,请填写`);
              return;
            }
            if (detailVOList[i].shopArea == "") {
              this.$message.warning(`第${i + 1}行面积不能为空或者填写不完整,请填写`);
              return;
            }
            if (detailVOList[i].shopLinearMeter == "") {
              this.$message.warning(`第${i + 1}行展厅延米不能为空或者填写不完整,请填写`);
              return;
            }
            // if (detailVOList[i].shapeId == "") {
            //   this.$message.warning(`第${i + 1}行厅型不能为空,请选择`);
            //   return;
            // }
            // if (detailVOList[i].shopArea == "") {
            //   this.$message.warning(`第${i + 1}行顶材质不能为空,请选择`);
            //   return;
            // }
            // if (detailVOList[i].shopArea == "") {
            //   this.$message.warning(`第${i + 1}行地材质不能为空,请选择`);
            //   return;
            // }
          }
        }
      } else {
        this.$message.warning(`请填写需求子单数据!`);
        return;
      }

      let data = {
        exhibitionStandDemandReportId: this.$route.query.id?this.$route.query.id:0,
        exhibitionStandDemandReportCode: "",
        orgId: this.marketCenterId,//立项组织
        demandProjectName: this.therm,//需求项目名称
        planProjectId: "14182428490",
        description: this.remark,// 说明
        attachmentList: this.fileIds, // 附件id
        detailVOList: detailVOList,
        discardDetailIdList: this.deletArr.map(x => x.id),//删除的子单id
        "officeManagerAccountId": this.bscManager,//办事处经理账户id
        "applyReason": this.content//弹框-申请原因
      };
      this.pageLoadFlag = true;
      tmpSaveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.pageLoadFlag = false;
          this.$message.success(res.data.msg);
          // 关闭窗口之前刷新父页面
          window.opener.location.reload();
          this.timer = setTimeout(() => {  //设置延迟执行
            this.goback();
          }, 2000);
        } else {
          this.pageLoadFlag = false;
          this.$message.warning(res.data.msg);
        }
      });
    },
    // 走审批流
    openWindow() {
      if (!this.marketCenterId) {
        this.$message.warning("请先填写立项组织!");
        return;
      }
      if (!this.therm) {
        this.$message.warning("请先填写需求项目名称!");
        return;
      }
      // if (!this.remark) {
      //   this.$message.warning("请先填写说明!");
      //   return;
      // }
      let detailVOList = [];
      if (this.tableData.length > 0) {
        this.tableData.forEach(item => {
          detailVOList.push({
              id: item.id,
              exhibitionStandReportId: "",
              materialGroupId: item.baseMatklId ? item.baseMatklId : "",
              customerShopId: item.shopId ? item.shopId : "",
              customerShopDetailId: item.cisDetailId ? item.cisDetailId : "", //item.officeId?item.officeId:
              demandTypeId: item.supplyTypeId ? item.supplyTypeId : "",
              sendOrderTypeCode: item.isHeaderUnion ? item.isHeaderUnion : "",
              supplierCode: item.SupplierId ? item.SupplierId : "",
              estimatedCost: item.costEstimate ? item.costEstimate : "",
              scheduledFinishTime: item.completionTime ? item.completionTime : "",
              targetSalesValWithinOneYear: item.saleT ? item.saleT : "",
              targetSalesMoneyWithinOneYear: item.salesVolumes ? item.salesVolumes : "",
              remark: item.remark ? item.remark : "",
              boothServiceLife: item.curStartYear && item.curEndYear ? item.curStartYear + "-" + item.curEndYear : "",
              annualSalesScale: item.FXnet,
              isBuildBrandPark:item.isBuildBrandpark,
              shopArea:item.shopArea,
              shopLinearMeter:item.shopLinearMeter,
              shapeId:item.shapedId,
              shapeText:item.shapedName,
              topMaterialId:item.topMaterialId,
              topMaterialText:item.topMaterialName,
              groundMaterialId:item.groundMaterialId,
              groundMaterialText:item.groundMaterialName
            }
          );
        });
        for (let i = 0; i < detailVOList.length; i++) {
          if (detailVOList[i]) {
            if (detailVOList[i].isBuildBrandPark == "") {
              this.$message.warning(`第${i + 1}行是否品牌园不能为空,请选择`);
              return;
            }
            if (detailVOList[i].customerShopId == "") {
              this.$message.warning(`第${i + 1}行门店不能为空,请选择`);
              return;
            }
            if (detailVOList[i].materialGroupId == "") {
              this.$message.warning(`第${i + 1}行物料组不能为空,请选择`);
              return;
            }
            if (detailVOList[i].demandTypeId == "") {
              this.$message.warning(`第${i + 1}行需求类型不能为空,请选择`);
              return;
            }
            if (detailVOList[i].sendOrderTypeCode == "") {
              this.$message.warning(`第${i + 1}行派单类型不能为空,请选择`);
              return;
            }
            if (detailVOList[i].estimatedCost == "") {
              this.$message.warning(`第${i + 1}行费用预估不能为空,请填写`);
              return;
            }
            if (detailVOList[i].estimatedCost == "") {
              this.$message.warning(`第${i + 1}行费用预估不能为空,请填写`);
              return;
            }
            if (detailVOList[i].scheduledFinishTime == "") {
              this.$message.warning(`第${i + 1}行要求完成时间不能为空,请选择`);
              return;
            }
            if (detailVOList[i].remark == "") {
              this.$message.warning(`第${i + 1}行说明不能为空,请填写`);
              return;
            }
            if (detailVOList[i].boothServiceLife == "") {
              this.$message.warning(`第${i + 1}行展台使用年限不能为空或者填写不完整,请填写`);
              return;
            }
            if (detailVOList[i].shopArea == "") {
              this.$message.warning(`第${i + 1}行面积不能为空或者填写不完整,请填写`);
              return;
            }
            if (detailVOList[i].shopLinearMeter == "") {
              this.$message.warning(`第${i + 1}行展厅延米不能为空或者填写不完整,请填写`);
              return;
            }
          }
        }
      } else {
        this.$message.warning(`请填写需求子单数据!`);
        return;
      }
      this.visible = true;
    },
    getReason($event, value) {
      this.bscManager = value;
      this.content = $event;
      this.visible = false;
      this.pageLoadFlag = true;
      this.confim();

    },
// 提交
    confim() {
      this.getDeletInfo();
      if (!this.marketCenterId) {
        this.$message.warning("请先填写立项组织!");
        this.pageLoadFlag = false;
        return;
      }
      if (!this.therm) {
        this.$message.warning("请先填写需求项目名称!");
        this.pageLoadFlag = false;
        return;
      }
      // if (!this.remark) {
      //   this.$message.warning("请先填写说明!");
      //   this.pageLoadFlag = false;
      //   return;
      // }
      let detailVOList = [];
      if (this.tableData.length > 0) {
        this.tableData.forEach(item => {
          detailVOList.push({
              id: item.id,
              exhibitionStandReportId: "",
              materialGroupId: item.baseMatklId ? item.baseMatklId : "",
              customerShopId: item.shopId ? item.shopId : "",
              customerShopDetailId: item.cisDetailId ? item.cisDetailId : "", //item.officeId?item.officeId:
              demandTypeId: item.supplyTypeId ? item.supplyTypeId : "",
              sendOrderTypeCode: item.isHeaderUnion ? item.isHeaderUnion : "",
              supplierCode: item.SupplierId ? item.SupplierId : "",
              estimatedCost: item.costEstimate ? item.costEstimate : "",
              scheduledFinishTime: item.completionTime ? item.completionTime : "",
              targetSalesValWithinOneYear: item.saleT ? item.saleT : "",
              targetSalesMoneyWithinOneYear: item.salesVolumes ? item.salesVolumes : "",
              remark: item.remark ? item.remark : "",
              supplierName: item.SupplierName ? item.SupplierName : "",
              officeId: item.officeId ? item.officeId : "",
              boothServiceLife: item.curStartYear && item.curEndYear ? item.curStartYear + "-" + item.curEndYear : "",
              annualSalesScale: item.FXnet,
            isBuildBrandPark:item.isBuildBrandpark,
            shopArea:item.shopArea,
            shopLinearMeter:item.shopLinearMeter,
            shapeId:item.shapedId,
            shapeText:item.shapedName,
            topMaterialId:item.topMaterialId,
            topMaterialText:item.topMaterialName,
            groundMaterialId:item.groundMaterialId,
            groundMaterialText:item.groundMaterialName
            }
          );
        });
        for (let i = 0; i < detailVOList.length; i++) {
          if (detailVOList[i]) {
            if (detailVOList[i].isBuildBrandPark == "") {
              this.$message.warning(`第${i + 1}行是否品牌园不能为空,请选择`);
              return;
            }
            if (detailVOList[i].customerShopId == "") {
              this.$message.warning(`第${i + 1}行门店不能为空,请选择`);
              this.pageLoadFlag = false;
              return;
            }
            if (detailVOList[i].materialGroupId == "") {
              this.$message.warning(`第${i + 1}行物料组不能为空,请选择`);
              this.pageLoadFlag = false;
              return;
            }
            if (detailVOList[i].demandTypeId == "") {
              this.$message.warning(`第${i + 1}行需求类型不能为空,请选择`);
              this.pageLoadFlag = false;
              return;
            }
            if (detailVOList[i].sendOrderTypeCode == "") {
              this.$message.warning(`第${i + 1}行派单类型不能为空,请选择`);
              this.pageLoadFlag = false;
              return;
            }
            if (detailVOList[i].estimatedCost == "") {
              this.$message.warning(`第${i + 1}行费用预估不能为空,请填写`);
              this.pageLoadFlag = false;
              return;
            }
            if (detailVOList[i].estimatedCost == "") {
              this.$message.warning(`第${i + 1}行费用预估不能为空,请填写`);
              this.pageLoadFlag = false;
              return;
            }
            if (detailVOList[i].scheduledFinishTime == "") {
              this.$message.warning(`第${i + 1}行要求完成时间不能为空,请选择`);
              this.pageLoadFlag = false;
              return;
            }
            if (detailVOList[i].remark == "") {
              this.$message.warning(`第${i + 1}行说明不能为空,请填写`);
              this.pageLoadFlag = false;
              return;
            }
            if (detailVOList[i].boothServiceLife == "") {
              this.$message.warning(`第${i + 1}行展台使用年限不能为空或者填写不完整,请填写`);
              return;
            }
            if (detailVOList[i].shopArea == "") {
              this.$message.warning(`第${i + 1}行面积不能为空或者填写不完整,请填写`);
              return;
            }
            if (detailVOList[i].shopLinearMeter == "") {
              this.$message.warning(`第${i + 1}行展厅延米不能为空或者填写不完整,请填写`);
              return;
            }
          }
        }
      } else {
        this.$message.warning(`请填写需求子单数据!`);
        this.pageLoadFlag = false;
        return;
      }
      let data = {
        exhibitionStandDemandReportId: this.$route.query.id?this.$route.query.id:0,
        exhibitionStandDemandReportCode: "",
        orgId: this.marketCenterId,//立项组织
        demandProjectName: this.therm,//需求项目名称
        planProjectId: "14182428490",
        description: this.remark,// 说明
        attachmentList: this.fileIds, // 附件id
        detailVOList: detailVOList,
        discardDetailIdList: this.deletArr.map(x => x.id),//删除的子单id
        "officeManagerAccountId": this.bscManager,//办事处经理账户id
        "applyReason": this.content//弹框-申请原因

      };
      this.pageLoadFlag = true;
      flowStart(data).then(res => {
        if (res.data.code == 0) {
          this.pageLoadFlag = false;
          this.$message.success(res.data.msg);
          // 关闭窗口之前刷新父页面
          window.opener.location.reload();
          this.timer = setTimeout(() => {  //设置延迟执行
            this.goback();
          }, 2000);
        } else {
          this.pageLoadFlag = false;
          this.$message.warning(res.data.msg);
        }
      });
    },
    goback() {
      window.close();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {
      // 要求完成时间
      this.addFrom.completionTime = defaultTime;
    },
    // 添加信息
    addline() {
      this.editType = 'add'
      if (!this.marketCenterId) {
        this.$message.warning("请选择立项组织");
        return;
      }
      this.addmessage=true
    },
    // 获取删除的数据
    getDeletInfo() {
      // 物料
      let A = this.tableData.map(x => x.id);
      let newList = this.tableDataOne.filter(item => !A.includes(item.id));

      // 定义一个数组 push所有删除的数组
      let deletArrww = [];
      this.deletArr = deletArrww.concat(newList);
      console.log(' this.deletArr', this.deletArr)
    }
  }

};