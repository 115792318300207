<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="648"
    :closable="false"
    centered
    :maskClosable="false"
    :ok-button-props="{ props: { disabled: submitBtn } }"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
    </div>
    <div class="cancelContent">
      <a-form
        :form="form"
        ref="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-form-item label="办事处经理：">
          <a-select
            placeholder="请输入办事处经理"
            show-search
            style="width:410px"
            v-model="FXDLlnum"
            :value="FXDLlnum"
            :data-source="FXDLlist"
            :filter-option="false"
            :defaultActiveFirstOption="true"
            @search="getDLList"
            @select="search"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option
              style="width:410px"
              :value="item.code"
              v-for="item in FXDLlist"
              :key="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>

    </div>
    <div class="cancelInfo">
      <span class="cancleZi">活动申请原因:</span>
      <a-textarea
        placeholder="请输入活动申请原因"
        :disabled="isEdit"
        @change="textChange"
        :value="textarea"
      />
    </div>
  </a-modal>
</template>

<script>
import service from "@/utils/request";
import { getUsers, getztUsers } from "./../addOredit/api";
export default {
  name:'cancelreason-modal',
  data(){
    return{
      fetching:false,
      submitBtn:true,
      selsetClear:false,
      list:[],
      isEdit:false,
      reason:'',
      textarea:'',
      FXDLlnum: '', // 模糊搜索 代理商id
      FXDLlist: [], // 模糊搜索出来的数据
      form: this.$form.createForm(this, { name: 'advanced_search' }),
    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    type:{
      type:String,
      default:''
    },
    tableData:{
      type:Array,
      default: () => []
    },
    orgId:{
      type:String,
      default:''
    },
  },
  mounted(){
  },
  methods:{
       // 输入模糊搜索代理商
    getDLList(value) {
      if(this.type == 'ztxq') {
        this.fetching = true
        this.FXDLlnum = value
        let searchArr = []
        this.tableData.forEach(item=>{
          searchArr.push({
            "orgId": this.orgId,//组织id
            "officeId": item.officeId,//办事处id
            "materialGroupIds": item.baseMatklId,//物料组id
            "searchKeyWords": this.FXDLlnum //查询关键字
          })
        })
        let data = searchArr
        getztUsers(data).then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data)

          } else {
            data = res.data;
          }

          this.FXDLlist = [...new Set(data.list)]; //缓存列表数据，前端分页

          this.fetching = false
        })
      } else {
        this.fetching = true
        this.FXDLlnum = value
        let data = {
          searchStr:this.FXDLlnum
        }
        getUsers(data).then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data)

          } else {
            data = res.data;
          }
          this.FXDLlist = [...new Set(data.data)]; //缓存列表数据，前端分页
          this.fetching = false
        })
      }

    },
    //查询数据
    search() {
     
    },
    textChange(e){
      if(e.target.value){
        this.submitBtn = false
      }
      this.reason = e.target.value
    },
    changeInput(value){
      
      this.submitBtn = true
    },
    handleOk(){
      // this.$emit('update:visible',false)
      this.$emit('cancelReasonHandOk',this.reason,this.FXDLlnum)
      this.textarea = ''
      this.form.resetFields();
      
    },
    cancle(){
      this.$emit('update:visible',false)
      this.textarea = ''
      this.form.resetFields();
      
    },
    
  }
}
</script>
<style lang="less" scoped>
  /deep/ .cancelName{
    font-family: PingFangSC-Medium, PingFang SC;
    
    .ant-modal-content{
      text-align:center;
      border-radius: 10px;
      .ant-modal-body{
        padding:30px 0px 0 40px;
        .cancelContent{
          text-align: left;
          padding:0 40px 0 0;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          .ant-tree-node-selected {
            background-color: red;
          }
          .ant-select-auto-complete.ant-select .ant-input { 
            width: 400px;
          }
          .ant-form {
            line-height: 32px;
          }
          .ant-form-item-control-wrapper{
            margin-left:16px;
          }
          .ant-form-item-control{
            line-height: 32px;
          }
          .ant-form-item-label{
            line-height: 32px;
          }
          .ant-form-item{
            margin-bottom:0;
          }
          .messageInfo{
            font-size: 12px;
            font-weight: 400;
            color: #AAAAAA;
            line-height: 12px;
            margin-top:8px;
            margin-left:86px;
          }
        }
        .cancelInfo{
          padding:24px 0 50px 0px;
          text-align: left;
          overflow: hidden;
          .cancleZi{
            width: 100px;
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            line-height: 20px;
            float:left;
            padding-left:11px;
            margin-right:9px;
          }
          .ant-input{
            width: 400px;
            height:88px;
            overflow: auto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
          .ant-input-affix-wrapper{
            width: 400px;
          }
        }
        .modal_close {
          position: absolute;
          top: 0;
          right: 0;
          width: 42px;
          height: 42px;
          line-height: 42px;
          border-radius: 0 10px 0 0;
          text-align: center;
          cursor: pointer;
          .modal_close_icon {
            color: #aaa;
            font-size: 16px;
          }
        }
        .modal_close:hover {
          background: #E4F8F7;
          .modal_close_icon {
            color: #08ABA8;
          }
        }
        .modal_title_box {
          text-align: center;
          padding-bottom:50px;
          .modal_title {
            position: relative;
            color: #262626;
            text-align: center;
            z-index: 10;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing:1px;
            .modal_title_line {
              display: inline-block;
              width: 100%;
              position: absolute;
              top: 13px;
              left: 0;
              border-top: 10px solid #E4F8F7;
              z-index: -10;
            }
          }
        }
      }
      p{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 30px;
      }
      .ant-modal-footer{
        text-align: center;
        border-top:0px solid #fff;
        padding:0px 0 30px;
        .ant-btn{
          width: 120px;
          height:40px;
          border-radius: 4px;
          font-size: 16px;
          color:#777;
        }
        .ant-btn-primary{
          color: #fff;
          background:#00AAA6;
          margin-left:24px;
        }
      }
    }
  }
</style>


