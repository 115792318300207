

import http from "@/utils/request";

// 获取列表
export function getofficeList(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getOffice.nd",
        data
    });
}
// 立项组织
export function queryOrg(data) {
    return http({
        url: '/comm/queryOrg.nd',
        method: 'post',
        data
    })
}
// 查询商家门店信息
export function getShopList(data) {
    return http({
        url: '/distributeBoothReport/findCustomerShop.nd',
        method: 'get',
        params:data
    })
}
// 根据门店id查询是否是专卖店接口
export function isSpecialShop(data) {
    return http({
        url: '/distributeBoothReport/findIsSpecialShop.nd',
        method: 'get',
        params:data
    })
}

// 获取需求类型列表
export function getXQtype() {
    return http({
        method: "get",
        url: "/comm/dict.nd?pid=" + '14182400533',
    });
}
// 派单类型
export function getFinOrderType(data) {
    return http({
        url: '/distributeBoothReport/finOrderTypeComboBox.nd',
        method: 'get',
        data
    })
}
// 查询 办事处
export function findShopDetail(data) {
    return http({
        url: '/distributeBoothReport/findShopDetail.nd',
        method: 'get',
        params:data
    })
}


// 模糊搜索供应商
export function SupplierComboBox(data) {
    return http({
        url: '/distributeBoothReport/findSupplierComboBox.nd',
        method: 'get',
        params:data
    })
}

// 暂存
export function tmpSaveInfo(data) {
    return http({
        url: '/distributeBoothReport/tmpSave.nd',
        method: 'post',
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    })
}
// 提交
export function flowStart(data) {
    return http({
        url: '/distributeBoothReport/flowStart.nd',
        method: 'post',
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    })
}


export function getUsers(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getUsers.nd",
        data,
    });
}

// 根据id查询详情

export function findDetail(data) {
    return http({
        method: "get",
        url: "/distributeBoothReport/findDetail.nd",
        params:data,
    });
}


// 根据门店id查询物料组
export function getMaterialGroups(data) {
    return http({
        url: '/distributeBoothReport/findMaterialGroups.nd',
        method: 'get',
        params:data
    })
}



export function getDLlnumSeach(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getAgent.nd",
        data
    });
}

export function getFXlnumSeach(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getDistributor.nd",
        data
    });
}


export function activityApplication(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/flowStart.nd",
        data,
        // contentType: "application/x-www-form-urlencoded",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

// 查看单条数据进行编辑 /custAgentActivity/getActivityById.nd
export function getActivityInfo(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getActivityById.nd",
        data
    });
}
// 获取活动编码字段
export function getActivityCode(data) {
    return http({
        method: "get",
        url: "/custAgentActivity/getActivityCode.nd",
        data
    });
}

// 查询参与品类的下拉项
export function getMatklList(data) {
    return http({
        method: "get",
        url: "/custAgentActivity/getMatklByCust.nd",
        params:data
    });
}
export function findCustomerShopAnnualSalesScale(data) {
    return http({
        method: "get",
        url: "/distributeBoothReport/findCustomerShopAnnualSalesScale.nd",
        params:data
    });
}
// 查询厅型
export function findHallTypeList(data) {
    return http({
        method: "get",
        url: "/distributeBoothReport/findHallType.nd",
        params:data
    });
}
// 查询顶
export function findTopMaterialList(data) {
    return http({
        method: "get",
        url: "/distributeBoothReport/findTopMaterial.nd",
        params:data
    });
}
// 查询地
export function findGroundMaterialList(data) {
    return http({
        method: "get",
        url: "/distributeBoothReport/findGroundMaterial.nd",
        params:data
    });
}





